import React, {ReactElement, useEffect, useState} from "react";
import "./BookingsOverview.css";
import {
    Box, Button,
    Collapse,
    Container,
    Grid, Icon, IconButton,
    Paper, Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {BookingService} from "../services/BookingService";
import {FmdBadOutlined, FmdGoodOutlined} from "@mui/icons-material";
import {Header} from "../common/Header";

interface BookingsOverviewPageProps {
    bookingService: BookingService
}

interface BookingsOverviewPageState {
    // @todo Types!
    bookings: any;
    loading: boolean
}

type Bookings = Array<Booking>

type Booking = {
    id: number,
    status: string
    attendee: Attendee,
    course: Course,
}

type Unit = {
    id: number,
    name: string,
    org_type_id: number
}

type Attendee = {
    id?: number | undefined,
    title: string,
    firstname: string,
    middlename: string,
    lastname: string,
    addr_street_nr: string,
    addr_line2?: string,
    addr_postcode: number | string,
    addr_city: string,
    email: string,
    mobile?: string,
    phone?: string,
    date_of_birth: string,
    unit_id: string,
    unit_function?: string,
    profession?: string,
    qualifications: Array<string>,
    gdpr_consents:  Array<any>
    unit: Unit,
}

type Course = {
    id: number,
    course_template_id: number,
    course_nr: string,
    registration_opens: string,
    registration_closes: string,
    start_date: string,
    end_date: string,
    created: string
}

const BookingsOverviewPage = (props: BookingsOverviewPageProps): any => {
    const initBookings: Bookings = [];
    const [bookings, setBookings] = useState<Bookings>(initBookings);

    const gdprWarning = (gdprStatus: string | null) => {
        if (gdprStatus === "YES") {
            return (<FmdGoodOutlined color="success" />)
        } else {
            return (<FmdBadOutlined color="warning"/>)
        }
    }
    const columns: GridColDef<(typeof bookings)[number]>[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'status',
            headerName: 'Status',
            width: 250,
            editable: false,
            renderCell: (params) => {
                return statusEffect(params.value)
            }
        },
        {
            field: 'created_at',
            headerName: 'Erstellt',
            width: 150,
            type: 'date',
            editable: false,
            valueGetter: (value) => value && new Date(value)
        },
        {
            field: 'course',
            headerName: 'Lehrgang',
            width: 150,
            editable: false,
            valueGetter: (value, row) => `${row.course.course_nr || ''}`,
        },
        {
            field: 'attendee',
            headerName: 'Teilnehmer',
            description: 'Klicken um zu Suchen und zu Sortieren.',
            sortable: true,
            width: 160,
            valueGetter: (value, row) => `${row.attendee.firstname || ''} ${row.attendee.lastname || ''}`,
        },
        {
            field: 'unit',
            headerName: 'Organisation',
            sortable: true,
            width: 200,
            valueGetter: (value, row) => `${row.attendee.unit.name|| ''} `,
        },
        {
            field: 'options',
            type: 'actions',
            sortable: false,
            width: 200,
            renderCell: (params) => {
                return <Link to={{pathname: `/buchung/workflow/${params.id}/`}}>Verwalten</Link>
            }
        },

    ];
    const statusEffect = (status: string) => {
        switch (status) {
            case "CONFIRMED":
                return (
                    <div className="status">
                        <i className="confirmed"></i>
                        <span>Bestätigt</span>
                    </div>);
            case "COST_APPROVAL":
                return (
                    <div className="status">
                        <i className="reserved"></i>
                        <span>Warten auf Kostenübernahme</span>
                    </div>);
            case "RESERVED":
                return (
                    <div className="status">
                        <i className="reserved-static"></i>
                        <span>Reserviert</span>
                    </div>);
            case "REJECTED":
                return (
                    <div className="status">
                        <i className="rejected"></i>
                        <span>Abgelehnt</span>
                    </div>);
            case "CLOSED":
                return (
                    <div className="status">
                        <i className="closed"></i>
                        <span>Abgeschlossen</span>
                    </div>);
        }
    }

    useEffect(() => {
        const getBookings = async () => {
            await props.bookingService.getBookings().then((json) => {
                setBookings(json.bookings)
            }).catch((e) => {
                console.error(e)
            })
        }
        getBookings().catch(console.error)
    }, [])

    if (bookings != undefined) {
        return (
            <Grid container maxWidth={'xl'} sx={{margin: 'auto', minHeight: "95vh"}}>
                <Header/>
                <Grid item xs={12} md={12} sx={{padding: '0 1rem 0 1rem', minHeight: "80vh"}}>
                    <Typography variant="h1">Buchungsübersicht</Typography>
                    <Box sx={{width: '100%' }} component={Paper}>
                        <DataGrid
                            rows={bookings}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 20,
                                    },
                                },
                            }}
                            pageSizeOptions={[20]}
                            checkboxSelection
                            disableRowSelectionOnClick
                        />
                    </Box>
                </Grid>
            </Grid>
        )
    }
}

export default BookingsOverviewPage;
