import {BookingService} from "../../services/BookingService";
import {LoginService} from "../../user/LoginService";
import {Link, useParams} from "react-router-dom";
import {
    Button,
    Checkbox,
    Container, FormControl,
    FormControlLabel,
    FormGroup, FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Header} from "../../common/Header";
import {Booking} from "../BookingDetails";

interface BookingWorkflowPageProps {
    bookingService: BookingService,
    loginService: LoginService
}

interface formDataType {
    [key: string]: FormDataEntryValue
}

// Refactoring of Workflow handling!

export const BookingWorkflowPage = (props: BookingWorkflowPageProps): any => {
    const {bookingId} = useParams()
    const initBooking: any = {id: 0, status: "", attendee: {}, course: {}};
    const [booking, setBooking] = useState<any>(initBooking);
    const [status, setStatus] = useState<any>();

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const manageBooking = async () => {
            return await props.bookingService.approveCosts(bookingId).then((json) => {
                window.location.assign("/buchungen")
            }).catch((e) => {
                console.error(e);
            })
        }
        manageBooking().catch(console.error)
    }

    const onSubmitHandler2 = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const manageBooking = async () => {
            return await props.bookingService.changeStatus(bookingId, status).then((json) => {
                window.location.assign("/buchungen")
            }).catch((e) => {
                console.error(e);
            })
        }
        manageBooking().catch(console.error)

    }

    const onStatusChangeHandler = (event: any) => {
        setStatus(event.target.value);
    }


    useEffect(() => {
        const getBooking = async () => {
            await props.bookingService.manageBooking(bookingId).then((json) => {
                setBooking(json.response)
            }).catch((e) => {
                console.error(e);
            })
        }
        getBooking().catch(console.error)
    }, [])

    const statusArray = (statusCode: string) => {
        const statusCodes: any = {
            "COST_APPROVAL": "Warten auf Kostenübernahme",
            "RESERVED": "Ich bestätige die Übernahme der Lehrgangskosten",
            "CONFIRMED": "Bestätigt",
            "REJECTED": "Abgelehnt",
            "WOI": "Warten auf Rückinformation",
            "CLOSED": "Geschlossen"
        };
        if (statusCodes[statusCode]) {
            return statusCodes[statusCode];
        } else {
            return "N/A";
        }
    }


    return (
        <Grid container maxWidth={'lg'} sx={{margin: 'auto', minHeight: "95vh"}}>
            <Header/>
            <Grid item xs={12} md={9} sx={{padding: '0 1rem 0 1rem', minHeight: "80vh"}}>
                <Typography variant={'h1'}>Buchung bearbeiten</Typography>
                {(function () {
                    if (booking.current_status == "COST_APPROVAL" && booking.next_status[0] != "REJECTED") {
                        return (<div>
                                <form onSubmit={onSubmitHandler2}>
                                    <Typography variant="h2">Aktueller Status: Kostenträgerfreigabe
                                        erforderlich</Typography>
                                    <Typography variant="h3">Bitte Bestätigen Sie die Teilnahme:
                                        <Typography>Teilnehmer: {booking.booking.attendee.lastname}, {booking.booking.attendee.firstname}</Typography>
                                        <Typography>{booking.booking.attendee.unit.name}</Typography>
                                        am Lehrgang {booking.booking.course.course_template.name} ({booking.booking.course.course_nr}):</Typography>
                                    <FormGroup>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="status-radios"
                                                name="status-radios"
                                                onChange={onStatusChangeHandler}>
                                                {booking.next_status.map((status: any, index: number) => (
                                                    <FormControlLabel value={status} control={<Radio/>}
                                                                      label={statusArray(status)} key={index}/>
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        <Button type="submit" variant="contained">Speichern</Button>
                                    </FormGroup>
                                </form>
                            </div>
                        )
                    } else if (booking.current_status != "COST_APPROVAL" && booking.current_status != undefined) {
                        return (
                            <div>
                                <Typography variant="h3">Bitte Bestätigen Sie die Teilnahme am
                                    Lehrgang {booking.booking.course.course_template.name} ({booking.booking.course.course_nr}):</Typography>
                                <form onSubmit={onSubmitHandler2}>
                                    <Typography>Teilnehmer: {booking.booking.attendee.lastname}, {booking.booking.attendee.firstname}</Typography>
                                    <Typography>{booking.booking.attendee.unit.name}</Typography>
                                    <FormGroup>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="status-radios"
                                                name="status-radios"
                                                onChange={onStatusChangeHandler}
                                            >
                                                {booking.next_status.map((status: any, index: number) => (
                                                    <FormControlLabel value={status} control={<Radio/>}
                                                                      label={statusArray(status)} key={index}/>
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        <Button type="submit" variant="contained">Speichern</Button>
                                    </FormGroup>
                                </form>
                            </div>
                        )
                    } else if (booking.current_status == "COST_APPROVAL" && booking.next_status[0] == "REJECTED") {
                        return (
                            <div>
                                <Typography variant="h3">Bitte Bestätigen Sie die Teilnahme am
                                    Lehrgang {booking.booking.course.course_template.name} ({booking.booking.course.course_nr}):</Typography>
                                <form onSubmit={onSubmitHandler2}>
                                    <Typography>Teilnehmer: {booking.booking.attendee.lastname}, {booking.booking.attendee.firstname}</Typography>
                                    <Typography>{booking.booking.attendee.unit.name}</Typography>
                                    <FormGroup>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="status-radios"
                                                name="status-radios"
                                                onChange={onStatusChangeHandler}
                                            >
                                                {booking.next_status.map((status: any, index: number) => (
                                                    <FormControlLabel value={status} control={<Radio/>}
                                                                      label={statusArray(status)} key={index}/>
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        <Button type="submit" variant="contained">Speichern</Button>
                                    </FormGroup>
                                </form>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <Typography>
                                    Hier gibts es aktuell nichts mehr zu tun. Sie können die Buchung aktuell nicht
                                    weiter
                                    bearbeiten.
                                </Typography>
                                <Link to={{pathname: `/buchungen/`}}>Zurück zur Buchungsübersicht</Link>
                            </div>
                        )
                    }
                })()}
            </Grid>
        </Grid>
    )
}
