import React, {useEffect, useState} from "react";
import "./AdminCoursesOverview.css";
import {
    Grid, Icon, IconButton,
    Paper, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {FmdBadOutlined, FmdGoodOutlined} from "@mui/icons-material";
import {Header} from "../../common/Header";
import {CourseService} from "../../services/CourseService";

interface AdminCoursesOverviewPageProps {
    courseService: CourseService
}

interface AdminCoursesOverviewPageState {
    // @todo Types!
    courses: Courses;
    loading: boolean
}

type Courses = {
    id: Array<any>
}

const AdminCoursesOverview = (props: AdminCoursesOverviewPageProps): any => {
    const initCourses: {[index: string]:any} = {};
    const [courses, setCourses] = useState<any>(initCourses);

    const gdprWarning = (gdprStatus: string | null) => {
        if (gdprStatus === "YES") {
            return (<FmdGoodOutlined color="success"/>)
        } else {
            return (<FmdBadOutlined color="warning"/>)
        }
    }

    const statusEffect = (status: string) => {
        switch (status) {
            case "CONFIRMED":
                return (
                    <div className="status">
                        <i className="confirmed"></i>
                        <span>Bestätigt</span>
                    </div>);
            case "COST_APPROVAL":
                return (
                    <div className="status">
                        <i className="reserved"></i>
                        <span>Warten auf Kostenübernahme</span>
                    </div>);
            case "RESERVED":
                return (
                    <div className="status">
                        <i className="reserved-static"></i>
                        <span>Reserviert</span>
                    </div>);
            case "REJECTED":
                return (
                    <div className="status">
                        <i className="rejected"></i>
                        <span>Abgelehnt</span>
                    </div>);
            case "CLOSED":
                return (
                    <div className="status">
                        <i className="closed"></i>
                        <span>Abgeschlossen</span>
                    </div>);
        }
    }

    useEffect(() => {
        const getCourses = async () => {
            await props.courseService.adminGetCoursesWithBookings().then((json) => {
                setCourses(json.bookings)
            }).catch((e) => {
                console.error(e)
            })
        }
        getCourses().catch(console.error)
    }, [])

    if (courses != undefined) {
        return (
            <Grid container maxWidth={'xl'} sx={{margin: 'auto', minHeight: "95vh"}}>
                <Header/>
                <Grid item xs={12} md={12} sx={{padding: '0 1rem 0 1rem', minHeight: "80vh"}}>
                    <Typography variant="h1">Kursverwaltung</Typography>
                    {Object.keys(courses).map((course: string, index: number) => (
                        <TableContainer component={Paper} style={{margin: '1rem 0 0 0', padding: '1rem'}}>
                            <Typography variant="h2">{Object.keys(courses)[index]}</Typography>
                            <div>
                                <ul>
                                  <a href={`/api/courses/download/${Object.keys(courses)[index]}`} target="_blank">Export</a>
                                </ul>
                            </div>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Erstellt</TableCell>
                                    <TableCell align="right">Lehrgang</TableCell>
                                    <TableCell align="right">Teilnehmer</TableCell>
                                    <TableCell align="right">Feuerwehr</TableCell>
                                    <TableCell align="right">Optionen</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {courses[course].map((booking: any, index: number) => (
                                    <TableRow>
                                        <TableCell>{booking.id}</TableCell>
                                        <TableCell>{statusEffect(booking.status)}</TableCell>
                                        <TableCell>{booking.created_at}</TableCell>
                                        <TableCell>{booking.attendee.unit.name}</TableCell>
                                        <TableCell sx={{
                                            display: 'inline-flex',
                                            alignItems: 'center'
                                        }}>{`${booking.attendee.lastname}, ${booking.attendee.firstname}`}</TableCell>
                                        <TableCell>{booking.attendee.unit.name}</TableCell>
                                        <TableCell>
                                            <Link to={{pathname: `/buchung/workflow/${booking.id}/`}}>Verwalten</Link>
                                            {/*<Link to={{pathname: `/buchung/details/${booking.id}/`}}>Details</Link>*/}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </TableContainer>
                    ))}

                </Grid>
            </Grid>
        )
    }
}

export default AdminCoursesOverview;
