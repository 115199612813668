import './App.css';
import Footer from "./common/Footer";
import {CoursesPage} from "./courses/CoursesPage";
import {LandingPage} from "./landing/LandingPage";
import {CourseService} from "./services/CourseService";
import BookingPage from "./booking/BookingPage";
import {ImprintPage} from "./leagal/Imprint";
import {DataPrivacy} from "./leagal/DataPrivacy";
import {ContactPage} from "./leagal/Contact";
import BookingDetailsPage from "./booking/BookingDetails";
import BookingsOverviewPage from "./booking/BookingsOverview";
import CourseDetailsPage from "./courses/CourseDetailsPage";
// @ts-ignore
import Manrope from "./fonts/Manrope-VariableFont_wght.ttf";
import {Login} from "./user/Login";
import {NotFound} from "./common/NotFound";
import {LoginService} from "./user/LoginService";
import React, {Context, createContext, useEffect, useState} from "react";
import {createTheme, CssBaseline, responsiveFontSizes, ThemeProvider} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {BookingService} from "./services/BookingService";
import {Profile} from "./user/Profile";
import {BookingWorkflowPage} from "./booking/workflow/BookingWorkflowPage";
import {GdprConsentPage} from "./gdpr/GdprConsentPage";
import AdminCoursesOverview from "./admin/courses/AdminCoursesOverview";

const themeRed = "#CA2B2B"

let theme = createTheme({
    palette: {
        primary: {
            main: themeRed
        }
    },
    typography: {
        h1: {
            fontSize: "2rem",
            color: themeRed,
            padding: "1rem 0"
        },
        h2: {
            fontSize: "1.5rem",
            color: themeRed,
            padding: "1rem 0"
        },
        h3: {
            fontSize: "1rem",
            fontWeight: "bold",
            padding: "1rem 0"
        },
        subtitle1: {
            color: "#CA2B2B"
        },
        fontFamily: [
            "Manrope",
            "Arial"
        ].join(',')
    }, components: {
        MuiButton: {
            variants: [
                {
                    props: {color: 'primary'},
                    style: {}
                }
            ]
        },
        MuiCssBaseline: {
            styleOverrides: `@font-face {
                font-family: 'Manrope';
                src: url(${Manrope});
                font-style: normal;
                font-weight: 300;
                }`
        }
    }
});

theme = responsiveFontSizes(theme)

const location = window.location;
const courseService = new CourseService();
const loginService = new LoginService();
const bookingService =  new BookingService();

const authContext = React.createContext(undefined);
const useAuth = ():any => {
    const [authed, setAuthed] = React.useState(false);
    return {
        authed,
        login() {
            return new Promise((res) => {
                setAuthed(true);
                res(true);
            });
        },
        logout() {
            return new Promise((res) => {
                setAuthed(false);
                res(true);
            });
        },
    };
}
export const AuthProvider = ({children}: any) => {
    const auth: any = useAuth()
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const AuthConsumer = () => {
    return React.useContext(authContext)
}

// @todo: Define User type
export const UserContext: Context<any> = createContext({user: null, setUser: (value: string | null) => {}})

function App() {

    const [user, setUser] = useState<any>(null)
    const value: any = {user, setUser}

    useEffect(() => {
        const user = async () => {
            loginService.session().then((response) => {
                if (response) {
                    setUser(response.user)
                } else {
                    console.log("IN No User");
                }
            })
        }
        user().catch(console.error)
    }, [])
    return (
        <ThemeProvider theme={theme}>
            <UserContext.Provider value={value}>
                <CssBaseline/>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<LandingPage location={location}/>}/>
                        <Route path="/kurse" element={<CoursesPage courseService={courseService}/>} />
                        <Route path="/lehrgangsleiter/kurse" element={<AdminCoursesOverview courseService={courseService}/>}/>
                        <Route path="/kurs/details/:courseAlias" element={<CourseDetailsPage courseService={courseService}/>}/>
                        <Route path="/buchung/:courseId" element={<BookingPage bookingService={bookingService} loginService={loginService}/>}/>
                        <Route path="/buchung/details/:bookingId/" element={<BookingDetailsPage bookingService={bookingService}/>}/>
                        <Route path="/buchung/workflow/:bookingId/" element={<BookingWorkflowPage bookingService={bookingService} loginService={loginService} />}/>
                        <Route path="/buchungen/" element={<BookingsOverviewPage bookingService={bookingService}/>}/>
                        <Route path="/impressum" element={<ImprintPage/>}/>
                        <Route path="/datenschutz" element={<DataPrivacy/>}/>
                        <Route path="/kontakt" element={<ContactPage/>}/>
                        <Route path="/gdpr/consent/:consentId" element={<GdprConsentPage />}/>
                        <Route path="/login" element={<Login loginService={loginService}/>}/>
                        <Route path="/profil" element={<Profile loginService={loginService}/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </BrowserRouter>
                <Footer/>
            </UserContext.Provider>
        </ThemeProvider>
    );
}

export default App;
