import React, {useContext, useEffect} from "react";
import {Box, Button, Grid, Container, Paper, Toolbar, Typography, useMediaQuery} from "@mui/material";
import './Landing.css';

import {Link} from "react-router-dom";
import {UserContext} from "../App";
import {Header} from "../common/Header";

interface LandingPageProps {
    location: Location,

}



export const LandingPage = (props: LandingPageProps) => {
    const {user, setUser} = useContext(UserContext);
    useEffect(() => {
    })
    return (
        <Grid container maxWidth={'lg'} sx={{margin: 'auto', minHeight: "95vh"}}>
            <Header />
            <Grid maxWidth={'lg'} container className={'landingHeroContainer'}>
                <Grid item xs={12} md={6} sx={{padding: '0 1rem 0 1rem'}}>
                    <h5 style={{color: '#CA2B2B', fontWeight: 'bold'}}>FEUERWEHREN DES LANDKREISES ROTH</h5>
                    <h1 style={{fontWeight: 'bold'}}>Zentrale<br/>Lehrgangsverwaltung</h1>
                    <Link to={'/kurse'}>
                        Zu den Lehrgängen
                    </Link>
                    <p>Ein kameradschaftliches Hallo! Schön, dass Ihr den Weg auf unsere neue
                        Lehrgangsplattform
                        gefunden habt!
                    </p>
                    <p>
                        Diese Plattform ist eine komplette Eigenentwicklung und wurde von uns ehrenamtlich
                        erstellt.
                        Das heißt: Es ist noch nicht alles perfekt oder fertig.
                    </p>
                    <p>
                        Viel Spaß beim zukünftigen Entdecken und ich freue mich auf euer Feedback.
                        Mit kameradschaftlichen Gruß
                    </p>
                    <p>
                        Timo Stark (Entwicklung) Feuerwehr Landkreis Roth
                    </p>
                </Grid>
                <Grid item>
                    <img src={require("./landing.jpg")} className="landinghero"/>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} sx={{padding: '0 1rem 0 1rem'}}>
                <h1 style={{textAlign: 'center'}}>
                    Liebe Feuerwehrkameradinnen<br/>und
                    Feuerwehrkameraden,
                </h1>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={require("./kbr-web.jpg")} alt="KBR Christian Mederer" height={400}
                     className="responsive shadow"/>
            </Grid>
            <Grid item xs={12} md={6} sx={{padding: '0 1rem 0 1rem'}}>
                <p>
                    ich freue mich ganz besonders, Euch auf unserer „Lehrgangsplattform“ begrüßen zu dürfen.
                </p>
                <p>
                    Die stetig steigenden Anforderungen im Einsatzgeschehen machen es notwendig, mit der
                    Zeit zu gehen und auch in der Ausbildung immer wieder darauf zu reagieren. Durch die
                    Zusammenwirkung aller Feuerwehren unseres Landkreises sowie mit unseren Kreiseinheiten
                    und der Möglichkeit pro Kreisbrandmeisterbereich Ausbildungseinheiten zu etablieren,
                    versuchen wir ein entsprechendes Aus- und Fortbildungsangebot anzubieten und zukünftig
                    weiter auszubauen.
                </p>
                <p>
                    Durch diese Maßnahmen und Erstellung unseres Lehrgangskataloges in Verbindung mit der
                    Lehrgangsplattform, versuchen wir stetig das Ausbildungsangebot anzupassen und zu
                    erweitern, um weiterhin einen qualitativ hochwertigen Ausbildungsstandart ergänzend zu
                    dem Lehrgangswesen auf den Staatlichen Feuerwehrschulen zu gewährleisten.
                </p>
                <p>
                    Diese Lehrgangsplattform soll zur Buchung der einzelnen Ausbildungen auf Landkreisebene
                    (Kreisausbildung) dienen. So können Kommandanten und Beauftragte entsprechend
                    Lehrgangsplätze mit den dazu angegebenen Terminen buchen und anmelden.
                    Die Lehrgangsabwicklung für den Bereich der Staatlichen Feuerwehrschulen bleibt davon
                    unberührt.
                </p>
                <p>
                    Ein besonderer Dank gilt an dieser Stelle allen Ausbildern, Helfern und Unterstützern
                    unseres Lehrgangswesens. Nur durch ihr Engagement und ihren unermüdlichen Einsatz lassen
                    sich die immer komplexer werdenden Lehrinhalte vermitteln und in die Fläche streuen.
                    Ebenfalls ein herzliches Dankeschön an alle Organisatoren und Gestalter, die dazu
                    beigetragen haben, unseren Lehrgangskatalog und diese Lehrgangsplattform zu erstellen
                    und zu gestalten.
                </p>
                <p>
                    Sollten doch mal Wünsche offen bleiben und spezielle Themen nicht ausreichend
                    berücksichtigt werden, bitte ich Euch dies unserem Kreisbrandmeister für
                    Ausbildungskoordination und/oder den zuständigen Führungskräften mitzuteilen. Bei Fragen
                    zu den Aus- und Weiterbildungen stehen die Führungskräfte und Ausbilder gerne zur
                    Verfügung.
                </p>
                <p>
                    Nun wünsche ich Euch viel Spaß bei der Bearbeitung des jeweiligen Lehrgangswesens, sowie
                    viel Freude bei einer interessierten Mitarbeit in unseren Ausbildungen.
                </p>
                <p>
                    Mit kameradschaftlichen Grüßen<br/>
                    Christian Mederer<br/>
                    <b>KBR Landkreis Roth</b>
                </p>
            </Grid>
        </Grid>
    );
}
