
export class LoginRedirectFetch {
    private location: Location;

    constructor(location: Location) {
        this.location = location;
    }
    private apiHost(): string {
        if (window.location.hostname === "localhost") {
            return "http://localhost:8080"
        } else {
            return "https://lehrgang.ff-lkr-roth.de"
        }
    }
    public async fetch(input: Request | string, init?: RequestInit, state?: string): Promise<Response> {
        const response = await fetch(this.apiHost()+input, init);
        // The Current Backend is not sending a 401 if the user is not authenticated BUT it is redirecting.
        // Refactor this!
        if (response.status === 401 || response.redirected ){
            let redirectLocation = '/login'
            if (state) {
                redirectLocation = `/login?state=${encodeURIComponent(state)}`
            }
            window.location.assign(redirectLocation)
        }
        return response;
    }
}
